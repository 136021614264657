export const ENTERPRISE_VALIDATION = "MARKETPLACE-ENTERPRISE_VALIDATION"

export const SECTORAL_CURATION = "MARKETPLACE-SECTORAL_CURATION"

export const PRODUCT_PRINCIPAL_SECTORAL =
  "MARKETPLACE-PRODUCT_PRINCIPAL_SECTORAL"

export const MARKETPLACE_NONKLPD_SPSE = "MARKETPLACE-NONKLPD_SPSE"
export const METABASE_AKUN_INAPROC = "ACCOUNT-METABASE-AKUN-INAPROC"

export const SHOW_SOCKET_GET_UPLOAD_STATUS_V1 =
  "ACCOUNT-KILL_SWICTH_AUTH_UPLOAD_STATUS_SOCKET"

export const ORDER_TERMINATION = "ORDER-ALLOW_INTERNAL_TO_TERMINATE_ORDER"

export const MULTISHIPMENT = "MULTISHIPMENT"

export const MULTISHIPMENT_JASA_DIGITAL = "MULTISHIPMENT_JASA_DIGITAL"

export const ORDER_CONFIRMATION_DELIVERY_DOCUMENT =
  "ORDER-SERVICEDIGITAL_CONFIRMATION_DELIVERY_DOCUMENT"

export const GRADUALLY_WORKING_PROGRESS = "GRADUALLY-WORKING-PROGRESS"

export const MARKETPLACE_MINICOM_ADJUSTMENT = "MARKETPLACE-MINICOM_ADJUSTMENT"
