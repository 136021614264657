"use client"
import { cx } from "class-variance-authority"
import { usePathname } from "next/navigation"
import { type Session } from "next-auth"
import { useContext, type ElementType } from "react"
import * as Icon from "react-feather"
import {
  Sidebar as SidebarComponent,
  SidebarMenu,
  SidebarSubmenu,
  Skeleton,
} from "shared-ui"
import { isSubpath } from "shared-ui/src/isSubpath"

import { getMenuItems, KODE_SATKER_LKPP } from "./config"
import { type MenuType } from "./type"
import { TRANSACTION_ALLOWED_ROLES } from "@/app/(sidebar-layout)/transaction/constants"
import {
  METABASE_AKUN_INAPROC,
  PRODUCT_PRINCIPAL_SECTORAL,
} from "@/commons/flags"
import { useGetInstitutionById } from "@/hooks/useGetInstitutionById"
import { hasPermissions } from "@/store/permissionStore"
import { FeatureFlagsContext } from "@/utils/featureFlag/FeatureFlagsProvider"

type TSidebarProps = {
  session: Session | null
}

const Sidebar = ({ session }: TSidebarProps) => {
  const pathname = usePathname()
  const featureFlags = useContext(FeatureFlagsContext)

  const isProductPrincipalSectoralEnabled =
    featureFlags?.[PRODUCT_PRINCIPAL_SECTORAL]?.isEnabled

  const isMetabaseAkunINAPROC = featureFlags?.[METABASE_AKUN_INAPROC]?.isEnabled

  const menuItems = getMenuItems(isMetabaseAkunINAPROC)

  const checkDefaultOpenCollapse = (menu: MenuType) => {
    if (!Boolean(menu.submenuItems)) {
      return undefined
    }

    const activeMenu = menu.submenuItems?.find((subMenu) => {
      if (pathname && isSubpath(subMenu.route, pathname)) {
        return true
      }
    })
    return Boolean(activeMenu?.route)
  }

  const { data: dataInstitution, isFetching: isLoadingInstitution } =
    useGetInstitutionById({
      enabled:
        !!session?.user?.institutionId &&
        session?.user?.permissions?.includes("order:view"),
      variables: { id: session?.user?.institutionId || "" },
      refetchOnWindowFocus: false,
    })

  const RenderSidebar = (menu: MenuType, isMain: boolean, level: number) => {
    if (!hasPermissions(session, menu.permissions || []) || menu.isHidden) {
      return null
    }

    if (
      menu.title === "Transaksi" &&
      session?.user?.role &&
      TRANSACTION_ALLOWED_ROLES.includes(session?.user?.role) &&
      dataInstitution?.satker?.kodeSatker !== KODE_SATKER_LKPP
    ) {
      return null
    }

    const newIcon = Icon as unknown as {
      [key: string]: string
    }

    const icon =
      typeof menu.icon === "string"
        ? menu?.icon
          ? (newIcon[menu.icon] as ElementType)
          : undefined
        : menu.icon

    return menu.submenuItems || isMain ? (
      <SidebarMenu
        key={menu.title}
        title={menu.title}
        Icon={icon}
        href={menu.route == "#" ? undefined : menu.route}
        collapsible={menu.submenuItems ? true : false}
        isDefaultOpenCollapse={checkDefaultOpenCollapse(menu)}
        level={level}
      >
        {menu.submenuItems?.map((submenu: MenuType) =>
          RenderSidebar(submenu, Boolean(submenu.submenuItems), level + 1)
        )}
      </SidebarMenu>
    ) : menu.route == "#" ? null : (
      <SidebarSubmenu
        title={menu.title}
        href={menu.route}
        key={menu.title}
        level={level}
      />
    )
  }

  const principalMenuItem: MenuType[] = [
    {
      icon: "Tag",
      route: "#",
      title: "Master Produk",
      permissions: ["masterProduct:view"],
      isParent: true,
      submenuItems: [
        {
          route: `/principal-applications/brand`,
          title: "Pemilik Berdasarkan Merek",
        },
        {
          route: `/principal-applications/sectoral`,
          title: "Pemilik Berdasarkan Kode Unik Sektoral",
          isHidden: !isProductPrincipalSectoralEnabled,
        },
      ],
    },
  ]

  const combinedMenuItems = [...menuItems, ...principalMenuItem]

  if (isLoadingInstitution) {
    return (
      <SidebarComponent className="radius-2xl card-outline box-border h-fit w-[255px] flex-shrink-0 px-6 py-4">
        {Array.from({ length: 4 }).map((_, idx) => (
          <Skeleton
            width="w-full"
            variant="rounded"
            height={cx("h-6", idx < 3 && "mb-4")}
            key={`sidebar-skeleton-${idx}`}
          />
        ))}
      </SidebarComponent>
    )
  }

  return (
    <>
      <SidebarComponent className="radius-2xl card-outline box-border h-fit w-[255px] flex-shrink-0 pt-4">
        {combinedMenuItems.map((menu: MenuType) =>
          RenderSidebar(menu, true, 1)
        )}
      </SidebarComponent>
    </>
  )
}

export default Sidebar
